body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Define default theme colors */
:root {
  --text-color-day: #090806;
  --background-color-day: #FAF7F5;
  --background-color-day-reverse: #333333;
  --primary-color-day: #FF8C00;
  --secondary-color-day: #E8BD88;
  --accent-color-day: #F0A952;
  --menu-color-day: #ffe7c9;

  --text-color-night: #F1F1F1;
  --background-color-night: #333333;
  --background-color-night-reverse: #FAF7F5;
  --primary-color-night: #607D8B;
  --secondary-color-night: #455A64;
  --accent-color-night: #FFAB40;
  --menu-color-night: #59554F;
}

[data-theme="day"] {
  --text-color: var(--text-color-day);
  --background-color: var(--background-color-day);
  --primary-color: var(--primary-color-day);
  --secondary-color: var(--secondary-color-day);
  --accent-color: var(--accent-color-day);
  --menu-color: var(--menu-color-day);
  --background-color-reverse: var(--background-color-day-reverse);
}

[data-theme="night"] {
  --text-color: var(--text-color-night);
  --background-color: var(--background-color-night);
  --primary-color: var(--primary-color-night);
  --secondary-color: var(--secondary-color-night);
  --accent-color: var(--accent-color-night);
  --menu-color: var(--menu-color-night);
  --background-color-reverse: var(--background-color-night-reverse)
}

body {
  color: var(--text-color);
  background-color: var(--background-color);
}
