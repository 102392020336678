.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  font-size: 1.3rem;
  cursor: pointer;
  font-weight: 500;
}

.menu .logoArea {
 width: 33%;
}

.menuLogo {
  width: 33%;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
}

.menuItem {
  width: 33%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
  font-size: 0.9rem;
}

.menuButtonItem:hover {
  color: #F0A952;
  cursor: pointer;
}

.menuLogin {
  width: 33%;
  display: flex;
  justify-content: end;
  font-size: 0.9rem;
}

.displayMenuDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.imgPlace {
  width: 20%;
}

.imgLogo {
  width: 80%;
}

.scoreText {
  font-size: 3rem;
  font-weight: 700;
}

.meText {
  font-size: 3rem;
  font-weight: 800;
  font-style: italic;
  background-color: #e8bd88;
  background-image: linear-gradient(45deg, #e8bd88, #eab97e, #ebb674, #edb26a, #eeae60, #f0aa56, #f2a64d, #f4a243, #f79d37, #f9982a, #fc921a, #ff8c00);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.menuIcon {
  cursor: pointer;
}

.modalMenu {
  position: absolute;
  right: 5%;
  top: 5%;
  background-color: var(--menu-color);
  margin-top: 15px;
  border-radius: 10px;
}

.icon-cross {
  display: flex;
}

.icon-hamburger {
  display: flex;
}

.buyCredits {
  background-color: #FF8C00;
  padding: 10px;
  border-radius: 30px;
}

.buyCredits:hover {
  background-color: #F0A952
}

.menuBlock {
  display: flex;
  flex-direction: row;
  padding: 8px 15px 8px 15px;
  justify-content: space-between;
  font-size: 0.8rem;
}

.menuUpgradeButton {
  width: 180px;
  background-color: #FF8C00;
  padding: 15px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
}

.menuUpgradeButton:hover {
  background-color: #F0A952;
}

.authMenuButton {
  width: 100%;
}

.authMenuButton span:hover {
  color: #ffffff;
}

.photoProfile {
  display: flex;
}

.theme-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.theme-toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.theme-toggle-label:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.theme-toggle-checkbox:checked+.theme-toggle-label {
  background-color: #4D4D4D;
}

.theme-toggle-checkbox:checked+.theme-toggle-label:before {
  transform: translateX(24px);
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color 0.4s;
}

.sun-icon {
  left: 6px;
  color: #FFD700;
  /* Gold color for the sun */
}

.moon-icon {
  right: 7px;
  color: #FFF;
  /* White color for the moon */
}

.theme-toggle-checkbox:checked+.theme-toggle-label .sun-icon {
  color: #4D4D4D;
  /* Less visible when switched */
}

.theme-toggle-checkbox:checked+.theme-toggle-label .moon-icon {
  color: #FFD700;
  /* Gold color for the moon */
}

@media screen and (max-width: 900px) {
  .scoreText {
    display: none;
  }

  .menu .logoArea {
    max-width: 33%;
    width: 15%;
   }

  .meText {
    display: none;
  }

  .menuLogo {
    width: 25%;
  }

  .imgPlace {
    width: 50%;
  }

  .amountCredentials {
    display: none;
  }

}

@media screen and (max-width: 450px) {
  .scoreText {
    display: none;
  }

  .meText {
    display: none;
  }

  .menuLogo {
    width: 15%;
  }
  
  .menuLogin{
    width: 15%;
  }

  .imgPlace {
    width: 100%;
  }
  
  .menuItem {
    gap: 10px;
  }

  .menu {
    margin: 0px 20px 0px 20px;
  }

  .amountCredentials {
    display: none;
  }

  .buyCredits {
    display: none;
  }

  .displayMenuDiv {
    gap: 5px;
  }

  .App-header {
    width: 100%;
  }

  .theme-toggle {
    top: 85px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

}