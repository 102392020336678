.animate-fade-drop {
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

/* Final state after animation */
.animated {
    opacity: 1;
    transform: translateY(0);
}

/* Delay animation for different elements for staggered effect */
.animate-delay-1 {
    transition-delay: 0.1s;
}

.animate-delay-2 {
    transition-delay: 0.5s;
}

.animate-delay-3 {
    transition-delay: 0.9s;
}

.landMainTest {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
}

.boxInDiv {
    background-color: #19161C;
    padding: 50px;
    border-radius: 20px;
    color: #fff;
    
}

.boxInInput {
    display: flex;
    background-color: #0A0A0A;
    border: none;
    border-radius: 10px 0px 0px 10px;
    height: 64px;
}

.boxInDiv button {
    background-color: var(--primary-color);
    font-size: 1rem;
    width: 150px;
    border: none;
    border-radius: 20px;
    margin: 10px;
    color: #fff;
}

.boxInDiv input {
    background-color: #0A0A0A;
    width: 100%;
    outline: none;
    color: #fff;
    padding-left: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 10px 0px 0px 10px;
}

.landHeaderTest {
    /* Centers the header */
    width: 90%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* Optional: Set a background color */
    z-index: 1000;
    /* Ensures the header stays on top of other content */
    padding: 0px 15px 0px 15px;
    /* Increased blur and shadow distance */
}

.landHeaderLaunch {
    position: absolute; 
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    /* Centers the header */
    width: auto;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* Optional: Set a background color */
    z-index: 1000;
    /* Ensures the header stays on top of other content */
    padding: 0px 15px 0px 15px;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.9);
    /* Increased blur and shadow distance */
}

.logoImgLaunch {
    width: 50px;
}

.logoTextLaunch {
    font-size: 1.5rem;
    font-weight: 600;
}

.waitlistButton a {
    text-decoration: none;
    
}

.waitlistButton a:hover {
    text-decoration: none;
    color: var(--primary-color);
}

.landButtons {
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 600;
}

.menuButton {
    color: #000000;
}

.menuButton:hover {
    color: var(--primary-color);
}

.buttonSignUp {
    background-color: var(--primary-color);
    padding: 10px;
    border-radius: 60px;
    font-weight: 600;
    color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    /* Increased blur and shadow distance */

}

.buttonSignIn {
    background: linear-gradient(165deg, #000000, #00010c, #000c16);
    /* Gradient background */
    padding: 10px;
    border-radius: 60px;
    font-weight: 600;
    color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    /* Increased blur and shadow distance */
}

.buttonSignIn:hover {
    background: var(--primary-color);
}

.buttonSignUp:hover {
    background: linear-gradient(165deg, #000000, #00010c, #000c16);
    /* Gradient background */
}

.landButtons a {
    text-decoration: none;
}

.descProductTextTest {
    font-size: 1rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 50px;
    width: 70%;
    text-align: center;
    align-items: center;
}

.arrow {
    width: 20%;
}

.boardBoxATest {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-bottom: 30px;
    width: 90%;
    text-align: start;
}

.boardBoxATest h3 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #00000079;
    margin-block-end: 0px;
}

.boardBoxATest h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-block-start: 0px;
}

.cards-container-Test {
    display: grid;
    grid-template-columns: 1.4fr 2fr;
    grid-template-rows: auto auto;
    gap: 16px;
    padding: 16px;
    background-color: var(--background-color, #f9f9f9);
  }
  
  .card-Test {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .summary-Test {
    grid-column: 1 / span 1;
    grid-row: 2;
    background: #6A8CE5; /* Light blue */
  }
  
  .video-Test {
    grid-column: 2;
    grid-row: 2 / span 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #012572; /* Subtle violet */
    
  }
  
  .new-user-Test {
    grid-column: 1 / span 1;
    grid-row: 3;
    background: #77AEA0; /* Light green */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .cardImgBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .new-user-Test img {
    width: 200px;
  }
  
  .description-Test {
    grid-column: 1 / span 2;
    grid-row: 1;
    background: #ED9BBD; /* Light yellow */
    padding: 24px;
  }
  
  .card-Test h3 {
    font-size: 1.5rem;
    margin-bottom: 12px;
    color: #fff;
  }
  
  .card-Test p {
    font-size: 1rem;
    color: #fff;
  }
  
  .stats-Test {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
    
  }
  
  .stat-Test {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 45%;
    height: 123px;
    justify-content: center;
    border-radius: 24px;
  }
  
  .stat-Test .value-Test {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .stat-Test .label-Test {
    font-size: 0.85rem;
    color: var(--text-color, #555);
  }
  
  .play-button-Test {
    background-color: #fff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
  }

  .dashedLineSvg {
    position: relative; /* Or relative, depending on your layout */
    top: 0;
    left: 0;
  }

  .notifBanner {
    width: 100%;
    background: #D38312;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #A83279, #D38312);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #A83279, #D38312); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-size: 1rem;
    color: white;
  }

  .notifBanner a {
    color: white;
  }
  
  .waveform-Test {
    width: 100%;
    height: 24px;
    background: linear-gradient(90deg, #ff6600, #ffaa33);
    border-radius: 8px;
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    padding: 0 8px;
    font-size: 0.85rem;
  }
  
  .services-button-Test {
    background-color: #fff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 12px;
  }
  
  .new-user-Test .value-Test {
    font-size: 2.5rem;
    color: #fff;
  }
  
.middleBoxTest {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
}

.middleBoxDiv {
    width: 30%;
}

.middleBoxDiv p {
    font-size: 1rem;
}

.middleBoxDiv img {
    width: 100%;
}

.middleBoxDivTwo {
    width: 70%;
}

.middleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.stepDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
}

.stepDivImage {
    display: flex;
    justify-content: center;
    width: 45%;
}

.stepImageBox {
    border-radius: 30px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepDivLine {
    width: 10%;
}

.stepDivText {
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    width: 45%;
}

.stepDivImage img {
    width: 70%;
    border-radius: 30px;
    padding: 30px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.229);
}

.boardBoxB {
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #000000, #00031a, #000000);
    /* Gradient background */

}

.boardBoxC {
    display: flex;
    justify-content: center;
}


.boardBoxTest {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60vh;
    width: 80%;
    align-items: center;
    position: relative;
    /* Add this to allow absolute positioning of the canvas */
    z-index: 2;
}

.boxCenterButton {
    display: flex;
    justify-content: center;
    
}

.circScore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.boardBoxMid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.leftBoardBoxTest {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-left: 20px;
    align-items: center;
}

.logoArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.logoImg {
    max-height: 50px;
}

.logoText {
    font-weight: 600;
    font-size: 1.4rem;
}

.rightBoardBox div {
    max-width: 400px;
    max-height: 400px;
}


.productTitleTest {
    color: #fff;
    font-size: 46px;
    text-align: center;
}

.productText {
    color: var(--primary-color);
}

.waitlistField {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #000000;
    padding: 5px;
    justify-content: space-between;
}

.waitlistField input {
    border: none;
    outline: none;
    font-size: 1rem;
}

.doggyAnim {
    position: fixed;
    right: -90px;
    top: 200px;
    z-index: 100;
    width: 200px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.waitlistButtonTest {
    background-color: var(--primary-color);
    padding: 15px 40px 15px 40px;
    border-radius: 30px;
    text-align: center;
    font-weight: 500;
    max-width: 256px;
    font-size: 1rem;
    color: #ffffff;
    
}

.waitlistButton:hover {
    color: var(--primary-color);
    background-color: #ffffff;
    cursor: pointer;
}

.cardArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.cardBox1 {
    display: flex;
    flex-direction: column;
    width: 60%;
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    text-align: start;
    transition: transform 0.3s, box-shadow 0.3s;
}

.cardBox2 {
    display: flex;
    flex-direction: column;
    width: 40%;
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    text-align: start;
    justify-content: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.cardBox3 {
    display: flex;
    flex-direction: column;
    width: 40%;
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.29);
    text-align: start;
    justify-content: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.cardBox1:hover {
    transform: translateY(-5px);
}

.cardBox2:hover {
    transform: translateY(-5px);
}

.cardBox3:hover {
    transform: translateY(-5px);
    cursor: pointer;
}

.landButtons span {
    cursor: pointer;
}

.sampleImage {
    display: block;
    width: 100%;
    height: 250px;
    border-radius: inherit;
    object-position: center center;
    object-fit: cover;
    image-rendering: auto;
}

.titleCard {
    font-weight: 600;
    font-size: 1.2rem;
    margin: 15px 10px 10px 10px;
}

.titleCardBig {
    font-weight: 600;
    font-size: 2.5rem;
    text-align: center;
}

.descCard {
    font-size: 1rem;
    margin: 0px 10px 10px 10px;
}

.boardBoxMid h2 {
    margin-block-end: 0px;
}

.boardBoxMid p {
    font-size: 1rem;
    margin-block-start: 0px
}

.aiReport {
    display: flex;
    flex-direction: row;
    border: 1px solid #000000;
    border-radius: 15px;
    padding: 10px;
    max-height: 500px;
}

.aiReportLeft {
    width: 60%;
}

.aiReportRight {
    width: 40%;
    overflow-y: scroll;
}

.aiReportLeft img {
    height: 90%;
    width: 100%;
    object-fit: cover;
    image-rendering: auto;
}

.percentanceBoard {
    background-color: var(--text-color);
    color: #ffffff;
    justify-content: space-between;
    border-radius: 30px;
    padding: 30px 30px 30px 30px;
    margin-top: 25px;
}

.percentCard {
    display: flex;
}

.percentText {
    width: 50%;
    text-align: start;
    padding: 5px;
}

/* Pop-up styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
}

.close-popup {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 20px 30px 0px 0px;
    float: right;
}


.percentText h2 {
    font-size: 3.5rem;
    color: var(--primary-color-day);
    margin-block-start: 0.4em;
}

.percentText p {
    font-size: 1.3rem;
}

.footerLand {
    border-top: 1px solid var(--primary-color);
    padding: 20px 0;
    text-align: center;
    background-color: var(--accent-background-color);
}

.footerRows {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 auto;
}

.footerColumns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.footerRow {
    flex: 1;
    padding: 0 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.footerRow div {
    margin-bottom: 10px;
    font-size: 16px;
}

.footerRow a {
    text-decoration: none;
}

.logoFooter {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footerRow:first-child {
    flex: 0 0 30%;
}

.footerRow:nth-child(2),
.footerRow:nth-child(3) {
    flex: 0 0 20%;
}

.footerLand>div:last-child {
    border-top: 1px solid var(--primary-color);
    padding: 25px 0px 15px 0px;
    font-size: 14px;
    color: #666;
    width: 90%;
    margin: 0 auto;
}

@media screen and (max-width: 1850px) {
    .landHeader {
        width: 35%;
    }

    .logoText {
        font-size: 1.2rem;
    }

    .logoImg {
        height: 40px;
    }
}

@media screen and (max-width: 1700px) {
    .landHeader {
        width: 38%;
    }

    .logoText {
        font-size: 1.2rem;
    }

    .logoImg {
        height: 40px;
    }
}

@media screen and (max-width: 1550px) {
    .landHeader {
        width: 38%;
        height: 70px;
    }

    .landButtons {
        font-size: 0.8rem;
    }

    .logoText {
        font-size: 1.2rem;
    }

    .logoImg {
        height: 45px;
    }
}

@media screen and (max-width: 1400px) {
    .landHeader {
        width: 38%;
        height: 70px;
    }

    .landButtons {
        font-size: 0.7rem;
    }

    .logoText {
        font-size: 1rem;
    }

    .logoImg {
        height: 40px;
    }
}

@media screen and (max-width: 1100px) {
    .landHeader {
        width: 40%;
        height: 60px;
        top: 15px;
    }

    .landButtons {
        font-size: 0.5rem;
    }

    .logoText {
        font-size: 0.8rem;
    }

    .logoImg {
        height: 30px;
    }
}

@media screen and (max-width: 800px) {
    .landHeader {
        width: 70%;
        height: 40px;
        top: 15px;
    }

    .buttonSignUp {
        padding: 6px;
    }

    .buttonSignIn {
        padding: 6px;
    }

    .landButtons {
        font-size: 0.5rem;
        gap: 8px;
    }

    .logoArea {
        gap: 5px;
    }

    .logoText {
        display: none;
    }

    .logoImg {
        height: 30px;
    }

    

    .boardBoxTest {
        flex-direction: column;
        width: 100%;
        height: 100vh;
        justify-content: center;
    }

    .boardBoxC {
        width: 100%;
    }
    
    .leftBoardBox {
        width: 80%;
        margin-top: 50px;
        padding-left: 0px;
    }

    .rightBoardBox div {
        max-width: 300px;
        max-height: 300px;
    }

    .boardBoxMid {
        width: 80%;
    }

    .cardArea {
        flex-direction: column;
    }

    .cardBox1 {
        width: 100%;
    }

    .cardBox2 {
        width: 100%;
    }

    .titleCard {
        font-size: 1rem;
    }

    .descCard {
        font-size: 0.8rem;
    }

    .titleCardBig {
        font-size: 1rem;
    }

    .aiReport {
        flex-direction: column;
    }

    .aiReportLeft {
        width: 100%;
    }

    .aiReportRight {
        width: 100%;
    }

    .percentCard {
        flex-direction: column;
    }

    .percentText {
        width: 100%;
    }

    .footerRows {
        width: 100%;
    }

    .footerRow div {
        font-size: 8px;
    }

    .landHeaderLaunch {
        top: 15px;
        width: 75%;
    }

    .logoTextLaunch {
        font-size: 1.2rem;
        text-align: left;
    }
}

@media screen and (max-width: 600px) {
    .productTitleTest {
        font-size: 30px;
    }

    .boardBoxATest h1 {
        font-size: 1.3rem;
    }

    .boardBoxATest h3 {
        font-size: 1rem;
    }

    .middleBoxTest {
        flex-direction: column;
    }

    .middleBoxDiv {
        width: 100%;
    }

    .middleBoxDivTwo {
        width: 100%;
    }

    .boxInDiv {
        padding: 20px;
    }

    .stepDiv {
        flex-direction: column;
    }

    .stepDivImage {
        width: 100%;
    }

    .stepDivLine {
        display: none;
    }

    .stepDivText {
        width: 100%;
    }
    
    .notifBanner {
        font-size: 0.5rem;
    }
  }