.rateBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rateUpper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin: 25px;
}

.error {
    font-size: 0.8rem;
    color: #da0000;
}

.error span {
    color: #000000;
    cursor: pointer;
}

.error span:hover {
    color: #636363;
    cursor: pointer;
}

.inputRateField {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.rateUpper input {
    height: 45px;
    width: 310px;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 6px 0px 0px 6px;
    border: 1px solid;
}

.rateResponse {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rateResponseImage {
    width: 60%;
    margin: 10px;
    overflow-y: auto;
    max-height: 75vh;
}

.rateResponseText {
    width: 40%;
    margin: 10px;
    overflow-y: auto;
    max-height: 75vh;
}

.criteriaList {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    padding: 0;
}

.criteriaItem {
    margin: 5px 0;
    padding: 10px;
    border: 1px solid var(--text-color); /* Default border color */
    cursor: pointer;
    font-size: 1rem;
    border-radius: 6px;
}

.criteriaItem.selected {
    border-color: #E8BD88; /* Border color when selected */
}

.enterButton {
    height: 47px;
    border-radius: 0px 6px 6px 0px;
    font-size: 1.1rem;
    padding: 0px 5px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;;
    background-color: #FF8C00;
    border: 0.5px solid #000000;
    cursor: pointer;
}

.customCardsSection {
    margin-top: 40px;
    padding: 20px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.customCardsHeader {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: var(--text-color);
}

.customCardsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    width: 70%;
}

.customCard {
    background-color: #242424;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    text-align: left;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: row;
}

.customCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.customCardImageWrapper {
    width: 50%;
}

.customCardImageWrapper img {
    border: 10px;
    margin: 20px;
    width: 90%;
    object-fit: cover;
    border-radius: 10px;
}

.customCardImage {
    width: 100%;
    height: auto;
    display: block;
}

.customCardContent {
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.customCardContent h3 {
    font-size: 1.4rem;
    margin-bottom: 12px;
    color: #ffffff;
}

.customCardContent p {
    font-size: 1rem;
    color: #cccccc;
    line-height: 1.5;
}


.enterButton:hover {
    background-color: #E8BD88;
}

.responseBox {
    border: 1px solid #F0A952;
    margin: 20px;
    padding: 10px;
    border-radius: 6px;
    font-size: 1.2rem;
    background-color: hsl(33, 100%, 50%, 5%);
}

/* Add this to your CSS file */
.scoreStyle {
    font-weight: bold;
    color: #F0A952; /* Example color */
}

.saveThisPost {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 95%;
    gap: 20px;
}

.saveThisButton {
    background-color: #FF8C00;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    display: flex;
}

.saveThisButton:hover {
    background-color: #F0A952;
}

.selectorProduct .pageOption {
    display: flex;
    gap: 10px;
    max-width: 400px;
    justify-content: space-between;
}

.selectorProduct .option {
    border: 1px solid var(--text-color);
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: border-color 0.3s;
    font-size: 1rem;
}

.selectorProduct .option input {
    display: none;
}

.selectorProduct .option.active {
    border-color: #E8BD88; /* Active border color */
}

.selectorProduct .option:hover {
    border-color: #E8BD88; /* Hover border color */
}


@media screen and (max-width: 450px) {
    .App body {
      width: 95%;
    }
    
    .selectorProduct select{
      width: 250px;
    }
    
    .rateUpper input{
      width: 180px;
    }

    .enterButton{
        width: 50px;
        font-size: 0.8rem;
    }

    .criteriaList {
        width: 90%;
    }

    .criteriaItem {
        font-size: 0.7rem;
    }

    .responseBox {
        margin: 0px 0px 10px 0px;
    }

    .rateResponse {
        flex-direction: column;
        align-items: center;
    }

    .rateResponseText {
        width: 100%;
    }

    .rateResponseImage {
        width: 100%;
    }

    .error {
        font-size: 0.8rem;
    }
    
    .customCard {
    flex-direction: column;
    }

    .customCardContent {
        width: 90%;
    }

    .customCardImageWrapper {
        display: none;
    }
  }