.signUpBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.signUpForm {
    display: flex;
    flex-direction: row;
    background-color: var(--accent-color);
    border-radius: 8px;
    padding: 0px 40px 0px 0px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.319);
}

.iconSocial {
    width: 20px;
}

.sign-up-form {
    max-width: 500px;
    width: 450px;
    height: 550px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sign-up-form h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.sign-up-form p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: #666;
}

.sign-up-form a {
    color: #000000;
    text-decoration: none;
}

.sign-up-form a:hover {
    text-decoration: underline;
}

.sign-up-form button {
    background-color: #fff;
    width: 50%;
    height: 40px;
    font-size: 0.8rem;
    border: none;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid #ccc;
    font-weight: 600;
    gap: 5px;
}

.checkboxReg {

}

.labelCheckbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0px 15px 0px;
    gap: 10px;
    align-items: center;
}

.checkbox-wrapper-12 {
    position: relative;
  }
  .checkbox-wrapper-12 > svg {
    position: absolute;
    top: -130%;
    left: -170%;
    width: 110px;
    pointer-events: none;
  }
  .checkbox-wrapper-12 * {
    box-sizing: border-box;
  }
  .checkbox-wrapper-12 input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    margin: 0;
  }
  .checkbox-wrapper-12 input[type="checkbox"]:focus {
    outline: 0;
  }
  .checkbox-wrapper-12 .cbx {
    width: 24px;
    height: 24px;
    top: calc(50vh - 12px);
    left: calc(50vw - 12px);
  }
  .checkbox-wrapper-12 .cbx input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #bfbfc0;
    border-radius: 50%;
  }
  .checkbox-wrapper-12 .cbx label {
    width: 24px;
    height: 24px;
    background: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-filter: url("#goo-12");
    filter: url("#goo-12");
    transform: trasnlate3d(0, 0, 0);
    pointer-events: none;
  }
  .checkbox-wrapper-12 .cbx svg {
    position: absolute;
    top: 5px;
    left: 4px;
    z-index: 1;
    pointer-events: none;
  }
  .checkbox-wrapper-12 .cbx svg path {
    stroke: #fff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 19;
    stroke-dashoffset: 19;
    transition: stroke-dashoffset 0.3s ease;
    transition-delay: 0.2s;
  }
  .checkbox-wrapper-12 .cbx input:checked + label {
    animation: splash-12 0.6s ease forwards;
  }
  .checkbox-wrapper-12 .cbx input:checked + label + svg path {
    stroke-dashoffset: 0;
  }
  @-moz-keyframes splash-12 {
    40% {
      background: var(--primary-color);
      box-shadow: 0 -18px 0 -8px var(--primary-color), 16px -8px 0 -8px var(--primary-color), 16px 8px 0 -8px var(--primary-color), 0 18px 0 -8px var(--primary-color), -16px 8px 0 -8px var(--primary-color), -16px -8px 0 -8px var(--primary-color);
    }
    100% {
      background: var(--primary-color);
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @-webkit-keyframes splash-12 {
    40% {
      background: var(--primary-color);
      box-shadow: 0 -18px 0 -8px var(--primary-color), 16px -8px 0 -8px var(--primary-color), 16px 8px 0 -8px var(--primary-color), 0 18px 0 -8px var(--primary-color), -16px 8px 0 -8px var(--primary-color), -16px -8px 0 -8px var(--primary-color);
    }
    100% {
      background: var(--primary-color);
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @-o-keyframes splash-12 {
    40% {
      background: var(--primary-color);
      box-shadow: 0 -18px 0 -8px var(--primary-color), 16px -8px 0 -8px var(--primary-color), 16px 8px 0 -8px var(--primary-color), 0 18px 0 -8px var(--primary-color), -16px 8px 0 -8px var(--primary-color), -16px -8px 0 -8px var(--primary-color);
    }
    100% {
      background: var(--primary-color);
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @keyframes splash-12 {
    40% {
      background: var(--primary-color);
      box-shadow: 0 -18px 0 -8px var(--primary-color), 16px -8px 0 -8px var(--primary-color), 16px 8px 0 -8px var(--primary-color), 0 18px 0 -8px var(--primary-color), -16px 8px 0 -8px var(--primary-color), -16px -8px 0 -8px var(--primary-color);
    }
    100% {
      background: var(--primary-color);
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }

.sign-up-form button:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.23);
}

.sign-up-form form {
    display: flex;
    flex-direction: column;
}

.sign-up-form label {
    font-size: 14px;
    color: #333;
}

.inputDivReg {
    display: flex;
    flex-direction: column;
}

.havingTrouble span {
    font-size: 0.8rem;
    font-weight: 500;
    float: left;
    padding: 15px 0px 15px 0px;
    cursor: pointer;
}

.sign-up-form input[type="email"],
.sign-up-form input[type="text"],
.sign-up-form input[type="password"] {
    padding: 13px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.sign-up-form input[type="checkbox"] {
    margin-right: 13px;
}

.sign-up-form .error {
    color: red;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
}

.sign-up-form button[type="submit"] {
    background-color: var(--accent-color);
    border-radius: 5px;
    padding: 13px;
    width: 100%;
}

.alreadyLogin {
    font-size: 0.8rem;
}

.alreadyLogin span {
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
}

.alreadyLogin span:hover {
    text-decoration: none;
}

.sign-up-form button[type="submit"]:hover {
    background-color: #f0a952a7;
    
}

.socButtons {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.signSocButton {
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
  .signUpForm {
    flex-direction: column;
  }

  .signUpBox {
    height: auto;
  }

  .sign-up-form {
    max-width: 600px;
    width: 100%;
    height: auto;
    padding: 20px;
  }
}