.circular-progress {
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-circle {
    stroke: white;
}

.score-text {
    font-size: 24px;
    fill: var(--text-color);
    font-weight: bold;
}
