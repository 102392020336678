.footerDivider {
    background-color: var(--text-color);
    height: 1px;
}

.footerContentArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 1rem;
    margin: 20px 0px 20px 0px;
}

@media screen and (max-width: 450px) {
    .footerContentArea {
        font-size: 0.7rem;
    }
  }