/* ParallaxSpace.css */
.parallax-background {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.parallax-background-test {
    position: relative;
    width: 90%;
    overflow: hidden;
    border-radius: 42px;
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
