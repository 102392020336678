.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
}

/* post-modal-content is the container inside the Modal */
.post-modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* large image on top */
  .post-modal-image img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 6px;
  }
  
  /* info section */
  .post-modal-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .post-modal-info h1 {
    margin-top: 0;
    font-size: 1.5rem;
  }
  
  /* likes/comments info */
  .post-likes {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .post-likes span {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  /* comments section */
  .comments-section {
    margin-top: 1.5rem;
  }
  
  .comments-section h2 {
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
  }
  
  .add-comment {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .add-comment textarea {
    width: 100%;
    min-height: 60px;
    padding: 0.5rem;
  }
  
  .add-comment button {
    align-self: flex-end;
    padding: 0.4rem 0.8rem;
    background-color: #ff6347;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .comments-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .comment-item {
    border: 1px solid #ddd;
    padding: 0.75rem;
    border-radius: 6px;
  }
  
  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
  }
  
  .comment-author {
    font-weight: bold;
  }
  
  .comment-likes {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .comment-actions {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
  }

  .comment-count {
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 50px;
    padding: 5px;
    border:#a4a4a467 solid 2px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
  }

  .comment-count:hover {
    border:#F0A952 solid 2px;
  }
  
  .comment-actions button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .edit-comment textarea {
    width: 100%;
    min-height: 40px;
    margin-bottom: 0.5rem;
  }
  

.responses-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
  }
  
  /* Minimal “long card” style for each post row */
  .response-card-minimal {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 1rem;
    border: 1px solid #eee; 
    border-radius: 6px;
    cursor: pointer;
    transition: box-shadow 0.2s;
    background-color: hsl(33, 100%, 50%, 5%);
    justify-content: space-between;
  }

  .response-card-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .response-name-div {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
  }
  
  .response-card-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .response-card-minimal:hover {
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  }
  /* The container inside your Modal */
.ph-style-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #333;
  }
  
  /* HEADER: product name, tagline, buttons */
  .ph-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .ph-modal-header-left {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .ph-product-title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: start;
  }
  
  .ph-product-subtitle {
    margin: 0;
    font-size: 1rem;
    color: #666;
    text-align: start;
  }
  
  .ph-modal-header-right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .founderBox {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .founderBox strong {
    font-size: 1rem;
  }

  .founderLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  /* VISIT / UPVOTE */
  .ph-visit-button {
    background: #fff;
    border: 1px solid #ddd;
    padding: 0rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    color: #333;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
    font-size: 1rem;
    height: 35px;
    display: flex;
    align-items: center;
  }
  .ph-visit-button:hover {
    background: #f9f9f9;
  }
  
  .ph-upvote-button {
    background: #ff644e;
    color: #fff;
    border: none;
    padding: 0rem 0.8rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 35px;
  }
  .ph-upvote-button:hover {
    opacity: 0.9;
  }
  
  /* MAIN: screenshot on left, details on right */
  .ph-modal-main {
    gap: 1.5rem;
    overflow-wrap: anywhere;
  }

  .comment-replies {
    margin-left: 1.5rem; /* Indent child replies */
    border-left: 1px solid #ccc; /* optional vertical line */
    padding-left: 1rem;
  }
  

  .headerModal {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .headerModal img {
    max-height: 50px;
  }
  
  /* Screenshot */
  .ph-modal-screenshot {
    flex: 1;
    max-width: 50%;
  }
  .ph-modal-screenshot img {
    border-radius: 6px;
    object-fit: cover;
    width: 368px;
    max-height: 220px;
    border: 1px solid #99999964;
    transition: box-shadow 0.2s;
    background-color: var(--background-color);
  }
  
  /* Info on the right side */
  .ph-modal-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    justify-content: start;
    align-items: start;
  }

  .ph-modal-details p {
    font-size: 0.8rem;
    text-align: justify;
  }

  .ph-screen-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  /* Score block */
  .ph-overall-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    width: 368px;
    max-height: 220px;
    border: 1px solid #99999964;
    transition: box-shadow 0.2s;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
  }

  .ph-overall-score h2{
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: var(--text-color);
  }
  
  /* COMMENTS SECTION */
  .ph-modal-comments {
    margin-top: 1rem;
  }
  
  /* "Comments (3)" heading */
  .ph-modal-comments h3 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }
  
  /* new comment row */
  .ph-new-comment {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  .ph-new-comment textarea {
    min-height: 60px;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    resize: none;
    font-family: "Poppins", sans-serif;
  }
  .ph-new-comment button {
    align-self: flex-end;
    background: #ff644e;
    color: #fff;
    border: none;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
  }
  .ph-new-comment button:hover {
    opacity: 0.9;
  }
  
  /* comment list */
  .ph-comments-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* each comment */
  .ph-comment-item {
    background: #f9f9f9;
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid #eee;
  }
  
  .ph-comment-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.4rem;
  }
  
  .ph-comment-author {
    font-weight: bold;
    color: #333;
    font-size: 0.8rem;
  }

  .ph-comment-item p{
    font-size: 0.7rem;
    text-align: start;
  }
  
  /* comment like button */
  .ph-comment-like {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  .ph-comment-like:hover {
    opacity: 0.8;
  }
  
  /* editing an existing comment */
  .ph-comment-edit textarea {
    width: 100%;
    min-height: 40px;
    margin-bottom: 0.4rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 0.4rem;
  }
  
  /* edit/delete */
  .ph-comment-actions {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
  }
  .ph-comment-actions button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 0.9rem;
  }
  .ph-comment-actions button:hover {
    text-decoration: underline;
  }
  
  
  .like-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1rem;
    float: right;
    color: var(--text-color);
  }
  
  .like-button .active {
    color: #FF8C00; /* highlight for liked state */
  }
  
  .response-icon {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 15%;
  }
  
  .response-name {
    font-weight: 600;
    font-size: 1rem;
  }

  .response-description {
    font-weight: 400;
    font-size: 0.8rem;
  }
  
  .response-score {
    font-size: 0.9rem;
    color: #333;
  }
  
  /* Optional: style your modal content when expanded */
  .titleInformationBox {
    margin-bottom: 1rem;
  }
  
  .titleInformationBox .date {
    margin-bottom: 0.5rem;
  }

.responseCard {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
    background-color: hsl(33, 100%, 50%, 5%);
    border-radius: 10px;
    padding: 20px;
    width: 70%;
    border: 1px solid #F0A952;
}

.overallScoreBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.responseCardSections {
    display: flex;
    flex-direction: row;
    width: 90%;
    gap: 30px;
}

.responseBoxBoard {
    width: 50%;
    max-height: 500px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    overflow-y: auto;
}

.responseBoxBoardRight {
    width: 50%;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 15px;
    border-radius: 5px;
}

.responseBoxBoardRightFeed {
    width: 50%;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 15px;
    border-radius: 5px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1.2rem;
    word-wrap: break-word;
}

.responseImage {
    width: 100%;
}

.titleInformationBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}


.divider {
    height: 1px;
    width: 100%;
    background-color: #F0A952;
}

.actionPostButtons {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.actionButton {
    width: 100px;
    padding: 10px 0px 10px 0px;
    border-radius: 30px;
    background-color: #FF8C00;
}

.actionButton:hover {
    background-color: #E8BD88;
    cursor: pointer;
}

.top-picks-list {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 50px;
}

.top-pick-item {
    position: relative;
    text-align: center;
    width: 150px;
}

.top-pick-item.position-1 {
    order: 1; /* Center this item */
}

.top-pick-item.position-2 {
    order: 0;
}

.top-pick-item.position-3 {
    order: 2;
}

.top-pick-item .user-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid var(--accent-color); /* Green border */
    box-shadow: 0 0 10px rgba(255, 183, 0, 0.5); /* Glowing effect */
    object-fit: cover;
}

.top-pick-item.position-1 .user-photo {
    width: 100px;
    height: 100px;
}

.crown-icon {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    color: gold;
    font-size: 20px;
}

.top-picks-widget {
    margin-bottom: 30px;
    width: 100%;
}

.top-pick-info {
    margin-top: 10px;
    color: rgb(0, 0, 0);
}

.username {
    font-size: 14px;
    color: #00ff88;
}

.likes-count {
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.329);
}


.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh;
    overflow-y: scroll;
    max-width: 530px;
}

.modalButtons button {
    margin: 10px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #FF8C00;
    border: none;
    border-radius: 5px;
}

.modalButtons button:hover {
    background-color: #F0A952;
}

.likes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.likeButton {
    display: flex;
}

.likeButton:hover {
    color: #cf1d1d;
    font-size: 1.2rem;
}

.likeButton.active {
    color: #cf1d1d;
    font-size: 1.2rem;
}

.dropOption {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.selectedOptionDrop {
    background-color: hsl(33, 100%, 50%, 5%);
    padding: 10px;
    border-radius: 10px;
    font-size: 1rem;
    border: 1px solid #F0A952;
}

.dropdownSorting {
    position: absolute;
    background-color: #FAF1E9;
    border: 1px solid #F0A952;
    border-radius: 10px;
    margin-top: 55px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}

.selectedOptionDropItem {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.selectedOptionDropItem:hover {
    background-color: #E8BD88;
    border-radius: 10px;
}

.responseSiteUrl {
    text-decoration: none;
    color: var(--text-color);
}

.responseSiteUrl:hover {
    color: var(--accent-color);
}

.responseImageBox {
    max-height: 500px;
}

@media screen and (max-width: 1000px) {
    .responseCardSections {
      flex-direction: column;
      width: 100%;
    }

    .responseBoxBoard {
        width: 100%;
    }

    .responseBoxBoardRight {
        width: 100%;
    }

    .responseBoxBoardRightFeed {
        width: 100%;
    }

    .titleInformationBox {
        font-size: 0.5rem;
    }

    .actionPostButtons {
        width: 80%;
        justify-content: center;
    }
    
    .dropOption {
        margin-top: 15px;
    }

    h3 .scoreVisual {
        display: flex;
        justify-content: space-between;
    }

    .datetime {
        display: none;
    }

    .top-picks-list {
        gap: 20px;
    }

    .top-pick-item {
        width: 100px;
    }

    .top-pick-info .username {
        font-size: 10px;
    }
  }

  @media screen and (max-width: 600px) {

    .response-card-minimal {
      flex-direction: column;
    }

    .response-card-right {
      width: 100%;
      justify-content: center;
    }

    .response-score {
      display: none;
    }

    .dropOption {
      margin-top: 35px;
    }

    .ph-modal-header {
      flex-direction: column;
    }

    .ph-modal-screenshot img {
      width: auto;
    }

    .responses-list {
      width: 70%;
    }
  }