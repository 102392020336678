.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 20px;
  z-index: 999;
}

.modal-content {
  background-color: #fff;
  width: 95%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.modal-close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 85%;
    max-height: 75%;
  }
}