.hallOfFame {
    text-align: center;
    padding: 20px;
  }
  
  .podium {
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Aligns the podium items at the bottom */
    margin-bottom: 40px;
  }
  
  .podium-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
  }
  
  .podium-item.first-place {
    order: 1; /* Ensure first place is in the middle */
    margin-top: -30px; /* Raise the first place */
  }
  
  .podium-item.second-place {
    order: 0;
  }
  
  .podium-item.third-place {
    order: 2;
  }
  
  .podium-image {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 4px solid #f0f0f0;
    margin-bottom: 10px;
  }
  
  .crown-icon {
    color: gold;
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .username {
    font-size: 16px;
    font-weight: bold;
    color: #444;
  }
  
  .likes {
    font-size: 14px;
    color: #666;
  }
  
  .product-list {
    margin-top: 20px;
    text-align: left;
  }
  
  .product-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    margin: 0 15%;
  }
  
  .rank {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    color: #555;
  }
  
  .list-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .username {
    flex: 1;
    font-size: 16px;
  }
  
  .likes {
    font-size: 16px;
    color: #666;
  }
  