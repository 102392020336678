.pricing-page {
    text-align: center;
    padding: 2rem;
  }
  
  .plan-selector {
    position: relative;
    max-width: 400px; /* adjust as necessary */
    margin: 0 auto;
    padding: 6px;
    border-radius: 30px;
    background-color: #e9e9e9cb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.259);
  }

  .saveLabel {
    font-size: 0.7rem;
    background-color: #f1f1f1e7;
    padding: 2px;
    color: #000000;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.259);
  }
  
  .bubble-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .plan-selector button {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    z-index: 1;
    cursor: pointer;
    transition: color 0.3s ease;
    font-weight: 600;
    position: relative;
  }
  
  .plan-selector button.active {
    color: white;
  }
  
  .active-bubble {
    position: absolute;
    background-color: var(--accent-color);
    border-radius: 30px;
    height: 100%;
    width: 25%;
    transition: all 0.3s ease-in-out;
    z-index: 0;
  }
  
  .active-bubble.Monthly {
    left: 0%;
  }
  
  .active-bubble.Yearly {
    left: 38%;
  }
  
  .active-bubble.Credits {
    left: 75%;
  }
  
  .plan-selector button:hover {
    color: #000000;
  }
  
  .pricing-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .priceCardTop {
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .priceCardLabel {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.784);
    padding: 2px 5px 2px 5px;
    border-radius: 30px;
    font-weight: 500;
    color: #000000;
  }

  .priceCardIcon {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.784);
    height: 50px;
    width: 50px;
    border-radius: 30px;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--accent-color);
  }
  
  .price-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 200px;
  }

  .pricePeriod {
    display: flex;
    align-items: center;
  }

  .pricePeriod span {
    font-size: 1rem;
    color: #000000;
  }
  
  .price-card h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: left;
    color: #000000;
  }

  .price-card p {
    font-size: 1rem;
    text-align: left;
    color: #000000;
  }
  
  .price-card h3 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
    margin-block-start: 0px;
    color: #000000;
  }
  
  .price-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    color: #000000;
  }
  
  .price-card ul li {
    margin-bottom: 8px;
    font-size: 1rem;
    text-align: left; 
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .price-card button {
    background-color: var(--accent-color);
    color: white;
    padding: 13px 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    width: 100%;
    font-weight: 600;
    margin-top: 20px;
  }

  .pricing-page h1 {
    margin-block-end: 0px;
    margin-block-start: 0px;
  }

  .desciptionAccessArea {
    display: flex;
    justify-content: center;
    align-items: start;
  }
  
  .desciptionAccess {
    text-align: center;
    font-size: 1rem;
    width: 50%;
  }
  
  .price-card button:hover {
    background-color: #f4a113a5;
  }
  
  @media screen and (max-width: 1000px) {
    .desciptionAccess {
      width: 100%;
    }

    .active-bubble.Monthly {
      left: 3%;
    }
    
    .active-bubble.Yearly {
      left: 38%;
    }
    
    .active-bubble.Credits {
      left: 73%;
    }
  }
