.adminDashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.postCardAdmins {
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-size: 1rem;
    gap: 10px;
    margin-bottom: 20px;
    overflow-y: scroll;
}

.postCardAdmins div{
    width: 300px;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #333;
    color: #fff;
    padding: 10px;
    max-height: 500px;
    overflow-y: scroll;
}

.postCardAdmins div img {
    max-width: 100%;
}

/* Container for the toggle switch */
.toggle-switch {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

/* Label next to the toggle */
.toggle-label {
    margin-left: 10px;
    font-weight: bold;
    font-size: 1.1em;
    color: #333;
}

/* The switch - the appearance of the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4caf50;
}

input:checked + .slider:before {
    transform: translateX(24px);
}
