.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
}

main {
  width: 100%;
  display: flex;
  justify-content: center;
}

.App body {
  width: 80%;
  font-family: "Poppins", sans-serif;
}

.App footer {
  width: 90%;
  text-align: center;
  background-color: --background-color;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mainBodyBox {
  width: 60%;
}

.selectorProduct select{
  width: 400px;
  height: 50px;
  border-radius: 6px;
  text-align: center;
  font-size: 1.2rem;
}

.mainTitle {
  margin-block-end: 0px;
}

.secondTitle {
  margin-block-start: 0px;
  font-size: 1.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: 90vw;
}

.App-link {
  color: #61dafb;
}

.productImage {
  width: 90%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F0A952;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E8BD88;
}

@media screen and (max-width: 450px) {
  .App body {
    width: 90%;
  }
  .secondTitle {
    font-size: 1rem;
  }
  
  .mainBodyBox {
    width: 100%;
  }
}

/* Star twinkle effect */
@keyframes starTwinkle {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}

/* Create moving stars effect */
.star {
  position: absolute;
  background: white;
  border-radius: 50%;
  animation: starTwinkle 2s infinite alternate ease-in-out;
}

.star::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: white;
  opacity: 0.1;
}
